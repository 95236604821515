<template>
  <batch-dialog
    :visible="visible"
    @close="closeAction"
    @cancel="cancelAction"
    @onOk="onOk"
    @onDialogOpened="onDialogOpened"
    @onDialogClosedAfterAction="onDialogClosedAfterAction"
    :record-operation="operation"
    :record-operation-label="operationLabel"
    :record-operation-icon="dialogIcon"
    :record-list="recordList"
    :destination-record="destinationRecord"
    :is-selected-record="isSelectedRecord"
    :batch-operation-status="batchOperationStatus"
    :alert="internalAlert"
    :progress="progressText"
  >
    <!-- More context -->
    <template v-slot:moreContext>
      <v-divider class="mt-3 mb-2"></v-divider>

      <!-- Item Template -->
      <div v-show="visibleItemTemplate">
        <v-subheader>
          Please Select Item Template
        </v-subheader>
        <v-select
          class="ml-8 mr-8"
          v-model="defaultTemplate"
          :items="agendaItemTemplates"
          item-text="name"
          item-value="index"
          label="Item Template"
          @change="onTemplateChanged"
          return-object
        ></v-select>
        <v-divider class="mt-3 mb-2"></v-divider>
      </div>

      <!-- Defer Options -->
      <v-subheader> {{ operationLabel }} Options </v-subheader>
      <expansion-panels-layout :inset="true">
        <!-- header -->
        <template v-slot:header>
          {{ operationLabel }} Options &emsp;
          <v-chip-group column show-arrows>
            <v-chip :small="visibleOnSmAndDown">{{
              deferOptionsTitle()
            }}</v-chip>
          </v-chip-group>
        </template>
        <template v-slot:content>
          <radio-group
            @change="onOptionChange"
            :options="options"
            :select-option="selectedOption.value"
          >
          </radio-group>
        </template>
      </expansion-panels-layout>
    </template>
  </batch-dialog>
</template>

<script>
// model
import {
  batchRecordStatus,
  contextMenuRecordOperation,
  findRecordOperation,
  recordOperation,
  recordType
} from "@/model/record/recordModel";

import {
  agendaItemToItemReferDeferOptions,
  agendaItemToSectionReferDeferOptions,
  findAgendaItemToItemReferDeferOption,
  findAgendaItemToSectionReferDeferOption
} from "@/model/agenda/item/agendaItemModel";

// mixins
import { promptAbleMixin } from "@/mixins/shared/promptAble/promptAbleMixin";
import { iconCopy, iconMovePage } from "@/design/icon/iconConst";
import { progressAbleMixin } from "@/mixins/shared/progressAble/progressAbleMixin";
import { actionResultType } from "@/model/action/actionModel";
import { moduleItemNavigatable } from "@/mixins/shared/navigatable/moduleItemNavigatable";
import { batchDialogMixin } from "@/mixins/shared/batch/batchDialogMixin";
import { alertableMixin } from "@/mixins/shared/alertable/alertableMixin";
import { reactiveMixin } from "@/mixins/shared/reactive/reactiveMixin";

export default {
  name: "BatchMoveItemsDialog",
  components: {
    BatchDialog: () => import("@/components/shared/core/dialogs/BatchDialog"),
    ExpansionPanelsLayout: () =>
      import(
        "@/components/shared/core/expansionPanel/ExpansionPanelsLayout.vue"
      ),
    RadioGroup: () => import("@/components/shared/core/radio/RadioGroup.vue")
  },
  mixins: [
    promptAbleMixin,
    progressAbleMixin,
    moduleItemNavigatable,
    batchDialogMixin,
    alertableMixin,
    reactiveMixin
  ],
  props: {
    recordList: {
      type: Array,
      default: () => []
    },
    destinationRecord: undefined,
    moveOperation: undefined,
    moveEvent: {
      type: Function,
      required: true
    },
    moveEventCompleted: {
      type: Function,
      required: true
    },
    agendaItemTemplates: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      iconCopy: iconCopy,
      iconMove: iconMovePage,
      isSelectedRecord: null,
      isCanceled: false,
      batchOperationStatus: [],
      selectedOption: undefined,
      options: agendaItemToItemReferDeferOptions,
      defaultTemplate: undefined
    };
  },

  computed: {
    /**
     * Move Items Operation
     * @return {{name: string, icon: string, label: string}} Move Record Operation
     */
    operation() {
      return findRecordOperation(recordOperation.Move);
    },

    /**
     * Copy Items Operation Label
     * @return {string} Copy Items Operation Label
     */
    operationLabel() {
      return this.moveOperation === contextMenuRecordOperation.Copy
        ? "Refer"
        : "Defer";
    },

    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.moveOperation === contextMenuRecordOperation.Copy
        ? this.iconCopy
        : this.iconMove;
    },

    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return `${this.operationLabel}`;
    },

    /**
     * Is Visible Agenda Item Template
     * @return {boolean}
     */
    visibleItemTemplate() {
      return this.agendaItemTemplates.length !== 1;
    }
  },

  methods: {
    /**
     * Move Progress Text
     * @param record
     * @param index
     * @return {string}
     */
    moveProgressText(record, index) {
      return `${this.operationLabel}ing ${record.name} \n  ${index} of ${this.recordList.length}`;
    },

    /**
     * Performs Move Record
     */
    async onOk() {
      try {
        this.clearAlert();
        for (let i = 0; i < this.recordList.length; i++) {
          if (!this.isCanceled) {
            const record = this.recordList[i];
            this.isSelectedRecord = record;

            this.progressText = this.moveProgressText(record, i + 1);

            const result = await this.moveEvent(
              record,
              this.destinationRecord,
              this.moveOperation,
              this.selectedOption.value,
              this.defaultTemplate.index
            );

            if (result?.type === actionResultType.success) {
              this.setStatus(record.id, batchRecordStatus.Completed, "Success");
              //await this.autoCloseDialog(result.message);
            } else if (result?.type === actionResultType.abort) {
              this.setStatus(record.id, batchRecordStatus.Canceled, "Canceled");
              //this.closeDialog();
            } else {
              this.setStatus(
                record.id,
                batchRecordStatus.Failed,
                result.message
              );
              this.internalAlert = this.createAlert(
                this.alertTypeName.error,
                this.formatAlertError(this.action, result.message),
                false
              );
            }
          }
        }

        this.internalAlert = this.createAlertSuccess(
          `${this.action} Operation Completed`,
          false
        );
      } catch (e) {
        this.closeProgress();
        this.isCanceled = true;
        this.internalAlert = this.createAlert(
          this.alertTypeName.error,
          this.formatAlertError(this.action, e),
          false
        );
      } finally {
        this.isCanceled = false;
        this.closeProgress();
        this.isSelectedRecord = undefined;
        //this.closeDialog();
      }
    },

    /**
     * Event on Dialog Closed After Action Performed
     * @return {Promise<void>}
     */
    async onDialogClosedAfterAction() {
      await this.$router.push(
        this.createModuleItemContentRoute(this.destinationRecord?.id)
      );
      await this.moveEventCompleted();
    },

    /**
     * Event On Insert Option Change
     * @param option
     */
    onOptionChange(option) {
      this.selectedOption =
        this.destinationRecord?.recordTypeId === recordType.SECTION
          ? findAgendaItemToSectionReferDeferOption(option)
          : findAgendaItemToItemReferDeferOption(option);
    },

    /**
     * Set Refer Options
     */
    deferOptions() {
      this.options =
        this.destinationRecord?.recordTypeId === recordType.SECTION
          ? agendaItemToSectionReferDeferOptions
          : agendaItemToItemReferDeferOptions;
    },

    /**
     * Event On Template Changed
     * @param template
     */
    onTemplateChanged(template) {
      this.defaultTemplate = template;
    },

    /**
     * Refer Option Title
     * @return {String}
     */
    deferOptionsTitle() {
      return this.selectedOption.text;
    }
  },

  updated() {
    //Set Defer Option when Dialog loads
    this.deferOptions();

    //Set Default Insert Option when Dialog loads
    this.selectedOption =
      this.destinationRecord?.recordTypeId === recordType.SECTION
        ? agendaItemToSectionReferDeferOptions[1]
        : agendaItemToItemReferDeferOptions[2];

    //Set Default Template when Dialog loads
    this.defaultTemplate = this.agendaItemTemplates[0];
  }
};
</script>
